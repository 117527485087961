import $ from 'jquery'
$(function () {
  var flagVal = true
  $(document).on('click', '#headUl li', function () {
    flagVal = false
    $(this).addClass('active').siblings().removeClass('active')
    var index = $(this).index()
    var distanceH = $('.right dl').eq(index).offset().top - 100
    $('html,body').animate(
      {
        scrollTop: distanceH
      },
      function () {
        flagVal = true
      }
    )
  })
  $(window).scroll(function (e) {
    var scrollDocTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    if (scrollDocTop > 400) {
      $('#headUl').addClass('toUlFixed')
    } else {
      $('#headUl').removeClass('toUlFixed')
    }
    if (flagVal) {
      var winH = $(window).innerHeight() / 4
      var scrollT = $(window).scrollTop()
      var len = $('#headUl li').length
      for (var i = 0; i < len; i++) {
        var bannerGap = $('.right dl')[i].offsetTop - scrollT
        if (bannerGap < winH) {
          $('#headUl li')
            .eq(i)
            .addClass('active')
            .siblings()
            .removeClass('active')
        }
      }
    }
  })
})
