<template>
  <div class="about">
    <Head :classA="classANum" headBg="1"></Head>
    <div class="main">
      <div class="bannerWrap">
        <img class="newsBanner" src="@/assets/images/about/aboutBanner.png" />
        <img class="newsIcon" src="@/assets/images/about/aboutIcon.png" />
      </div>
      <div class="outer">
        <div class="left">
          <ul id="headUl">
            <li class="active">公司简介</li>
            <li>大事记</li>
            <li>使命及价值观</li>
            <li>员工活动</li>
            <li>合作伙伴</li>
          </ul>
        </div>
        <div class="right">
          <dl id="introductionId" class="introductionWrap">
            <dt>公司简介</dt>
            <dd>
              <div class="listWrap">
                <div class="listText">
                  <p>
                    致医健康团队成立于2015年，是朗致集团内部孵化的互联网战略项目小组。依托朗致集团雄厚资源，于2016年正式注册成立公司———北京致医健康信息技术有限公司。公司成立以来，始终秉承“强基层，为天使插上翅膀”的企业使命，致力于通过建设适合基层的医疗互联网生态体系提升基层医疗的竞争力，通过“互联网+大健康+新零售”的全新商业模式，快速构建布局基层诊所端、药店端、供应链端、患者端、专家端等一系列解决方案并全面推向市场。
                  </p>
                </div>
              </div>
            </dd>
          </dl>
          <dl id="memorabiliaId" class="memorabiliaWrap">
            <dt>大事记</dt>
            <dd>
              <div class="listWrap">
                <div class="listText">
                  <ul class="time-axis">
                    <li class="time-axis-item time-axis-itemActive">
                      <div class="time-axis-date">
                        <strong>2020年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>1月</h6>
                        <p>平台门诊总量突破1亿</p>
                      </div>
                    </li>
                    <li class="time-axis-item">
                      <div class="time-axis-date">
                        <strong>2019年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>11月</h6>
                        <p>致医健康面向全国进行渠道招商代理</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>10月</h6>
                        <p>业务覆盖全国31省、318地市、2023县</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>7月</h6>
                        <p>获得医疗器械网络交易服务第三方平台备案凭证</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>6月</h6>
                        <p>致医云药房正式上线</p>
                      </div>
                    </li>
                    <li class="time-axis-item">
                      <div class="time-axis-date">
                        <strong>2018年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>12月</h6>
                        <p>合作诊所突破20000家，平台患者总量突破1000万</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>7月</h6>
                        <p>荣获高新技术企业认定</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>6月</h6>
                        <p>合作诊所突破10000家</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>2月</h6>
                        <p>全国范围展开体验式会销模式</p>
                      </div>
                    </li>
                    <li class="time-axis-item">
                      <div class="time-axis-date">
                        <strong>2017年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>12月</h6>
                        <p>合作诊所数量突破3000家</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>10月</h6>
                        <p>获得互联网药品信息服务资格证书</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>5月</h6>
                        <p>在职员工人数突破100人</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>1月</h6>
                        <p>确立“拥抱互联，领航基层”营销战略方针</p>
                      </div>
                    </li>
                    <li class="time-axis-item">
                      <div class="time-axis-date">
                        <strong>2016年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>11月</h6>
                        <p>北京致医健康信息技术有限公司正式注册成立</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>11月</h6>
                        <p>CCTV报道致医云诊室</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>7月</h6>
                        <p>朗致集团互联网战略发布会召开</p>
                      </div>
                    </li>
                    <li class="time-axis-item">
                      <div class="time-axis-date">
                        <strong>2015年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>12月</h6>
                        <p>致医云诊室正式上线</p>
                      </div>
                      <div class="time-axis-title">
                        <h6>5月</h6>
                        <p>朗致集团互联网云项目组成立</p>
                      </div>
                    </li>
                    <li class="time-axis-item">
                      <div class="time-axis-date">
                        <strong>2014年</strong>
                        <span></span>
                      </div>
                      <div class="time-axis-title">
                        <h6>11月</h6>
                        <p>朗致集团互联网战略项目启动需求调研</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </dd>
          </dl>
          <dl id="missionId" class="missionWrap">
            <dt>使命及价值观</dt>
            <dd>
              <ul class="missionUl">
                <li>
                  <h6>企业使命</h6>
                  <p>强基层，为天使插上翅膀</p>
                </li>
                <li>
                  <h6>企业战略</h6>
                  <p>通过建设适合基层的医疗互联网</p>
                  <p>生态体系提升基层医疗的竞争力</p>
                </li>
                <li>
                  <h6>企业价值观</h6>
                  <p>坦诚阳光、激情四射、不断变革</p>
                  <p>谦逊合作、恪守承诺、放飞梦想</p>
                </li>
                <li>
                  <h6>企业文化</h6>
                  <p>专业、创新、激情</p>
                </li>
              </ul>
            </dd>
          </dl>
          <dl id="employeesId" class="employeesWrap">
            <dt class="pt20">员工活动</dt>
            <dd>
              <ul class="employeesUl">
                <li>
                  <img src="@/assets/images/about/active1.png" alt />
                </li>
                <li>
                  <img src="@/assets/images/about/active2.png" alt />
                </li>
                <li>
                  <img src="@/assets/images/about/active3.png" alt />
                </li>
                <li>
                  <img src="@/assets/images/about/active4.png" alt />
                </li>
              </ul>
            </dd>
          </dl>
          <dl id="cooperationId" class="cooperationWrap">
            <dt class="pt20">合作伙伴</dt>
            <dd>
              <img
                class="cooperationImg"
                src="@/assets/images/about/cooperation2x.png"
                alt
              />
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import '../assets/style/leftNav.css';
</style>
<style lang="scss" scoped>
.about {
  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;

    .outer {
      .right {
        dl {
          dt {
            font-size: 20px;
            color: #333;
            text-align: left;
            padding: 0 0 14px 20px;
            font-weight: bold;
          }
          .pt20 {
            padding-top: 20px;
          }
          dd {
            .listWrap {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 30px;
              background: #fff;
              .listText {
                padding: 25px 30px;
                flex-grow: 1;
                h4 {
                  font-size: 16px;
                  color: #333;
                  text-align: left;
                  padding-bottom: 10px;
                  font-weight: bold;
                }

                p {
                  font-size: 14px;
                  color: #333;
                  text-align: left;
                  line-height: 22px;
                  text-align: justify;
                }
              }

              img {
                width: 100%;
                max-width: 344px;
              }
            }
            .time-axis {
              padding: 19px 0;
              position: relative;
              .time-axis-title {
                text-align: left;
                display: flex;
                // flex-wrap: wrap;
                justify-content: flex-start;
                padding-bottom: 20px;
                h6 {
                  color: #333;
                  padding-right: 20px;
                  width: 34px;
                }
                p {
                  color: #666;
                }
              }
              .time-axis-date {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 26px;
                font-weight: 600;
                color: rgba(204, 204, 204, 1);
                line-height: 32px;
                strong {
                  display: inline-block;
                  width: 90px;
                  font-weight: bold;
                }
                span {
                  position: absolute;
                  right: -70px;
                  top: 0;
                  display: block;
                  width: 30px;
                  height: 30px;
                  background: url('../assets/images/about/icongray.png')
                    no-repeat;
                  background-size: 100%;
                }
              }

              .time-axis-item::before {
                content: '';
                position: absolute;
                left: 143px;
                top: 15px;
                width: 4px;
                height: 100%;
                background-color: rgba(245, 245, 245, 1);
              }
              .time-axis-item:last-child::before {
                height: 0;
              }
              .time-axis-item:last-child {
                padding: 3px 0 0 200px;
              }
              .time-axis-item {
                position: relative;
                line-height: 22px;
                font-size: 14px;
                color: #666;
                padding: 3px 0 60px 200px;
              }
              .time-axis-item.time-axis-itemActive {
                .time-axis-date {
                  color: #2213de;
                }
                .time-axis-date span {
                  background: url('../assets/images/about/iconblue.png')
                    no-repeat;
                  background-size: 100%;
                }
              }
            }
          }
        }
        dl.introductionWrap {
          dd {
            .listWrap {
              .listText {
                p {
                  line-height: 28px;
                }
              }
            }
          }
        }
        dl.memorabiliaWrap {
          dd {
            .listWrap {
              .listText {
                padding: 25px 30px 10px 30px;
              }
            }
          }
        }
        dl.missionWrap {
          .missionUl {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
              width: 49%;
              height: 170px;
              margin-bottom: 20px;
              padding: 30px;
              box-sizing: border-box;
              background: #fff;
              text-align: left;
              h6 {
                font-size: 20px;
                color: #333;
                font-weight: bold;
                padding-bottom: 20px;
              }
              p {
                font-size: 16px;
                color: #666;
                line-height: 22px;
              }
            }
          }
        }
        dl.employeesWrap {
          .employeesUl {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
              width: 49%;
              height: 100%;
              margin-bottom: 20px;
              img {
                width: 100%;
              }
            }
          }
        }
        dl.cooperationWrap {
          .cooperationImg {
            width: 100%;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 620px) {
    .main .outer .right dl.missionWrap .missionUl li {
      width: 100%;
      height: auto;
    }
    .main .outer .right dl dd .time-axis .time-axis-date {
      font-size: 18px;
    }
    .main .outer .right dl dd .time-axis .time-axis-item {
      padding: 3px 0 30px 110px;
    }
    .main .outer .right dl dd .time-axis .time-axis-item:last-child {
      padding: 3px 0 0 110px;
    }
    .main .outer .right dl dd .time-axis .time-axis-item::before {
      left: 93px;
    }
    .main .outer .right dl dd .time-axis .time-axis-date span {
      width: 16px;
      height: 16px;
      right: -13px;
      top: 8px;
    }
    .main .outer .right dl dd .time-axis .time-axis-title {
      padding: 3px 0 10px 0;
    }
    .main .outer .right dl dd .time-axis .time-axis-title p {
      width: 70%;
    }
    .main .outer .right dl dd .time-axis .time-axis-title h6 {
      width: 50px;
    }
  }
}
</style>
<script>
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'
import '../assets/js/leftNav.js'

export default {
  name: 'news',
  components: {
    Head,
    Foot
  },
  data () {
    return {
      classANum: '4'
    }
  },
  methods: {},
  mounted () {}
}
</script>
